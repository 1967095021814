import { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import { HTTPResponse, Role, ToastMessageType, } from "../../Helpers/Enums";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CenterService } from "../../WebApiServices/Center.service";
import { GetLocalStorageData } from "../../Helpers/Utilities";

const ChartSummary = () => {
  const [CohortTreatment, setCohortTreatment] = useState([]);
  const [cohortList, setCohortList] = useState([]);

  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

  const [centers, setCenters] = useState([]);
  const [allRounds, setAllRounds] = useState([]);
  const [rounds, setRounds] = useState([]);

  const [selectedCenter, setSelectedCenter] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState(null);

  const [selectedRound, setSelectedRound] = useState(null);
  const [selectedRoundId, setSelectedRoundId] = useState(null);

  const [uniquePtCount, setUniquePtCount] = useState(0);
  const [prevRoundCnt, setPrevRoundCnt] = useState(0);

  let userDetails = GetLocalStorageData("user");
  userDetails = userDetails && JSON.parse(userDetails);
  const centerId = userDetails?.centerId ?? 0;
  const roleId = userDetails?.userRole ?? 0;
  const isAdmin = roleId === Role.Admin ?? false;

  async function PopulateRoundDropdown(centerId) {
    try {
      const center = centers.find(a => a.centerId === centerId);
      let activeRoundId = 0;
      let roundName = null;
      let round_dd = [];

      if (center) {
        const roundIds = center.roundIds;
        round_dd = allRounds.filter(r => roundIds.includes(r.roundId));

        activeRoundId = center.activeRoundId;
        roundName = round_dd.find(r => r.roundId === activeRoundId).roundName;
      }

      setRounds(round_dd);
      setSelectedRound(roundName);
      setSelectedRoundId(activeRoundId);
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }
  async function LoadRoundAssociateddData() {
    try {
      debugger
      ToggleLoader(true);
      const response = await CenterService.GetPatientCohortData(selectedCenterId, selectedRoundId);
      ToggleLoader(false);
      if (response?.status === HTTPResponse.OK) {
        debugger
        const { data } = response;
        setCohortTreatment(data.centerTreatmentRoundList);
        setUniquePtCount(data.lockedPatientCount);
        setPrevRoundCnt(data.lockedPatientCountPreviousRound);
      } else if (response?.status === HTTPResponse.Unauthorized) {
        HandleSessionTimeout();
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }
  useEffect(() => {
    if (selectedCenterId && selectedRoundId) {
    LoadRoundAssociateddData();
    }
  }, [selectedCenterId, selectedRoundId])
  useEffect(() => {

    async function FetchData() {
      ToggleLoader(true);
      await LoadData();
      ToggleLoader(false);
    }
    FetchData();
  }, []);

  async function LoadData() {
    try {
      // const response = await CenterService.GetAllCentersAndRounds(centerId, isAdmin);
      let response = {};
      if (isAdmin) {
        response = await CenterService.GetAllCentersAndRounds();
      } else {
        response = await CenterService.GetAllCenterRounds(centerId);
      }
      if (response?.status === HTTPResponse.OK) {
        const { data } = response;
        if (data) {
          const { centerList, roundList, cohortList } = data;
          setCohortTreatment(cohortList);
          setCohortList(cohortList);
          if (isAdmin) {
          setAllRounds(roundList);
          setCenters(centerList);
          } else {
            //abstractor logged in
            const { center } = data;
            if (center) {
              const activeRoundId = center.activeRoundId;
              setSelectedCenter(center.centerName);
              setSelectedCenterId(center.centerId);
              setSelectedRoundId(activeRoundId);
              const roundName = roundList.find(r => r.roundId === activeRoundId).roundName;
              setSelectedRound(roundName);
              setRounds(roundList);
            }
          }
        }
      } else if (response?.status === HTTPResponse.Unauthorized) {
        HandleSessionTimeout();
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const HandleCenterChange = (center) => {
    const centerId = center.centerId;
    const centerName = center.centerName;
    setSelectedCenter(centerName);
    setSelectedCenterId(centerId);

    // Fetch rounds for the selected center
    PopulateRoundDropdown(centerId);
  };

  const HandleRoundChange = async (round) => {
    const roundId = round.roundId;
    const roundName = round.roundName;
    setSelectedRound(roundName);
    setSelectedRoundId(roundId);
  };

  // Reset the selected center n round
  const HandleReset = () => {
    setSelectedCenter(null);
    setSelectedRound(null);

    setSelectedCenterId(null);
    setSelectedRoundId(null);
    setRounds([]);
    setCohortTreatment(cohortList);
  }
  return (
    <>
      <section className="survey-patient-details-section ">
        <PatientDetails />
      </section>

      <section className="main-survey-section custom-chart">
        <div className="container">
          <div className="survey-section-content">
            <div className="answer-pad-left double-dig-answer">



              <div className="dropdown-fbox" >


                <Dropdown className='custom-dropdown-caret'
                >
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="w150 custom-dropdown-button"
                    style={{ width: '250px', whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left' }}
                    disabled={!isAdmin}
                  >
                    {selectedCenter ?? 'Select Center'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-ht custom-dropdown-menu" >
                    {centers.map(c => (
                      <Dropdown.Item
                        as="button"
                        name="centerDropdown"
                        key={c.centerId}
                        value={c.centerName}
                        className={`custom-dropdown-item ${c.centerName === selectedCenter ? 'custom-dropdown-item-selected' : undefined}`}
                        onClick={() => HandleCenterChange(c)}
                      >
                        {c.centerName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='custom-dropdown-caret' >
                  <Dropdown.Toggle variant="" id="dropdown-basic" className="w150 custom-dropdown-button"
                  >
                    {selectedRound ?? 'Select Round'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="custom-dropdown-ht custom-dropdown-menu" >
                    {rounds.map(r => (
                      <Dropdown.Item
                        as="button"
                        name="centerDropdown"
                        key={r.roundId}
                        value={r.roundName}
                        className={`custom-dropdown-item ${r.roundName === selectedRound ? 'custom-dropdown-item-selected' : undefined}`}
                        onClick={() => HandleRoundChange(r)}
                      >
                        {r.roundName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {isAdmin &&
                <button
                  className="custom-save-button btn btn-outline-light btn-lg add-new-patient"
                  type="submit"
                  onClick={HandleReset}
                >
                  Reset
                </button>
                }
              </div>

              <div className="table-page">
                <table>
                  <thead>
                    <tr>
                      <th rowspan="2">Treatment Regimens</th>
                      <th colspan="5">
                        <span style={{ fontSize: "20px" }}>
                          {" "}
                          Round {selectedRoundId}
                        </span>
                      </th>
                    </tr>
                    <th>Target number of new charts</th>
                    <th>Charts satisfying eligibility criteria</th>
                    <th>
                      Abstracted new charts
                      <br/>
                      Unique Patient Count: {uniquePtCount}
                    </th>
                    <th>Number of charts abstracted in previous rounds
                        : {prevRoundCnt}
                    </th> 
                    <th>Chart updates</th>
                  </thead>
                  <tbody style={{backgroundColor: "white"}}>
                    {CohortTreatment.map((row) => (
                      <tr>
                        <td>
                          {row.cohortTreatmentName}
                          {row.cohortTreatmentNameSuperscript == null ? (
                            ""
                          ) : (
                            <>
                              <sup className="supscript">
                                {row.cohortTreatmentNameSuperscript.slice(0, 2)}
                              </sup>
                              {row.cohortTreatmentNameSuperscript.slice(2)}
                            </>
                          )}
                        </td>
                        <td className="blue-text">{row.newCharts ?? 0}</td>
                        <td className="blue-text">
                          {row.eligibilityChartUpdates ?? 0}
                        </td>
                        <td className="blue-text">
                          {row.abstractedNewCharts ?? 0}
                        </td>
                        <td className="blue-text">
                          {row.abstractedPreviousCharts ?? 0}
                        </td>
                        <td className="blue-text">{row.chartUpdates ?? 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChartSummary;
