import React, { useContext, useEffect, useState } from 'react'
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { RRMMCohortTreatment, SideMenuItems } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import PatientDetails from '../../Components/PatientInformation';
import { GetLocalStorageData } from '../../Helpers/Utilities';
import { AppContext } from '../../Contexts/AppContextProvider';


const AEReporting = () => {
    
    const [navigatePaths, setNavigatePaths] = useState({
        prevPage: "/AdverseEvents",
        currPage: "/AEReporting",
        nextPage: "/ValidateSubmit",

    })

    const { isSideMenuActive,setIsSideMenuActive } = useContext(AppContext);
    
    const SetNavPaths = () => {
        const storedData = GetLocalStorageData("CohortTreatment");
        let cohortTreatmentModelListt = [];
        let newPaths = { ...navigatePaths };
    
        if (storedData) {
          cohortTreatmentModelListt = JSON.parse(storedData);
        }
    
        let eligibleCohorts = cohortTreatmentModelListt.filter(
          (c) => c.isEligible === true
        );
        let ndmmCohorts = eligibleCohorts.filter((c) => c.ndmm);
        let rrmmCohorts = eligibleCohorts.filter((c) => !c.ndmm);
    
        if (rrmmCohorts.length > 0) {
            newPaths.prevPage = "/AdverseEvents";
        } else {
            newPaths.prevPage = "/TreatmentResponseProgression";
        }
        setNavigatePaths(newPaths);
    };

    const SavePage  =  () => {
        //SetNavPaths();
        return true;
    }
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
    

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
    SetNavPaths();
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} /> 
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div >
                    <SideBarMenu activePageId={SideMenuItems.AEReporting} />
                    <>
                    <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
                   </>
                   
                    <div className="col-pad-left" id='main'>
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>AE Reporting</h4>
                            </div>
                            <div className="survey-question-section">
                                <div className="survey-question-content">

                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                            <div className="question-weight">
                                                <p >To report Adverse Events (AEs) at your site, follow these steps:</p>

                                                <ol>
                                                    <li>
                                                        <span className="instructon-point-spacing" >
                                                            Submit each AE via &nbsp; 
                                                            <a
                                                                href="http://www.janssensafety.com"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                www.janssensafety.com
                                                            </a>
                                                            , which is linked in the AE reporting
                                                            section of the eCRF.
                                                           
                                                        </span>
                                                       <br/><br/>
                                                        <ul>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    The "Initial Receipt Date" (referred to as the <span style={{ fontWeight: "bolder" }}>"J&J receipt date"</span> in EZR) is the
                                                                    date you become aware of the AE during service, not when the patient first
                                                                    reported it to their physician. This date marks <span style={{ fontWeight: "bolder" }}>Day 0</span> for reporting, and the AE
                                                                    must be submitted to J&J within <span style={{ fontWeight: "bolder" }}>one day</span>. If the AE is discovered on a Friday,
                                                                    report it by the following Monday.
                                                                </span>
                                                                <br/><br/>
                                                            </li>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    Include the <span style={{ fontWeight: "bolder" }}>Program Number PCSONCA0334</span> with each AE submission.
                                                                    Failure to do so will lead to resubmission and possible noncompliance.
                                                                </span>
                                                                <br/><br/>
                                                            </li>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    Please use the following <span style={{ fontWeight: "bolder" }}>Approval/Authorization Number</span> for each product
                                                                    when reporting AEs:
                                                                    <ul>
                                                                        <li>
                                                                            <span style={{ fontWeight: "bolder" }}>Teclistamab: 761291</span> (corresponds to TECVAYLI)
                                                                        </li>
                                                                        <li>
                                                                            <span style={{ fontWeight: "bolder" }}>Talquetamab: 761342 </span>(corresponds to TALVEY)
                                                                        </li>
                                                                        <li>
                                                                            <span style={{ fontWeight: "bolder" }}> Ciltacabtagene autoleucel: 125746</span> (corresponds to CARVYKTI)
                                                                        </li>
                                                                        <li><span style={{ fontWeight: "bolder" }}>Daratumumab</span>: please select the authorization number
                                                                            corresponding to the formulation administered
                                                                        </li>
                                                                        <ul style={{ listStyleType:'disc'}}>
                                                                            <li>
                                                                                <span style={{ fontWeight: "bolder" }}>761036</span> (corresponds to DARZALEX concentrate solution for
                                                                                intravenous infusion)
                                                                            </li>
                                                                            <li>
                                                                                <span style={{ fontWeight: "bolder" }}>761145</span> (corresponds to DARZALEX FASPRO solution for
                                                                                subcutaneous injection)
                                                                            </li>
                                                                        </ul>
                                                                    </ul>

                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <br />
                                                        <br/>
                                                    </li>
                                                    <li>
                                                        <span className="instructon-point-spacing">
                                                            After submission, promptly update the <span style={{ fontWeight: "bolder" }}>Excel AE tracking log </span>(shared to your team's
                                                            abstractors through ShareFile) with the following details:

                                                        </span>
                                                        <br/><br/>
                                                        <ul>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    Ensure no duplication of <span style={{ fontWeight: "bolder" }}>vendor-assigned tracking numbers</span> (pre-populated in log), especially if multiple chart abstractors are involved.
                                                                </span>
                                                                <br/>
                                                            </li>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    The <span style={{ fontWeight: "bolder" }}>date the AE</span> was reported to J&J.
                                                                </span>
                                                                <br/>
                                                            </li>
                                                            <li>
                                                                <span className="instructon-point-spacing">
                                                                    Record the <span style={{ fontWeight: "bolder" }}>J&J confirmation number</span> from the EZR reporting website in the
                                                                    tracking log to complete the process.
                                                                </span>
                                                                <br/>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ol>
                                            
                                                <br />
                                                <div>
                                                <p style={{ textAlign: 'left' }}>Following these steps ensures timely and accurate AE reporting. Please reach out to
                                                        eMMpower@analysisgroup.com with any questions.</p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} /> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
          </>
       
    );
}

export default AEReporting