export const ToastTimeout = 4000;
export const HTTPResponse = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  NotAllowed: 405,
  Conflict: 409,
  InternalServerError: 500,
};
export const AppContextIntialState = {
  isLoading: false,
  showToast: false,
  toastMessage: "",
  actionPopUp: {
    isOpen: false,
    title: "Alert",
    description: "Are you sure?",
    confirmButtonLabel: "Yes",
    disableCancelBtn: false,
  },
  toastType: undefined,
  pageDataChanged: false,
  showTopMenu: false,
  activeTab: undefined,
  isSideMenuActive: true,
};

export const ToastMessageType = {
  Success: 0,
  Failed: 1,
};

export const TopMenuItems = {
  Patients: 1,
  Centers: 2,
  Abstractors: 3,
  Logout: 4,
  ChartSummary: 5,
};
export const CenterMenuItems = {
  CenterInfo: 1,
  Round: 2,
};
export const SideMenuItems = {
  PatientScreening: 1,
  ChartSelection: 2,
  GeneralInformation: 4,
  NDMMCriteria: 5,
  RRMMCriteria: 6,
  ExclusionCriteria: 7,
  TransitionToChartAbstraction: 8,
  Demographics: 9,
  DiseaseHistory: 10,
  Comorbidities: 11,
  CohortDefiningTreatment: 12,
  NDMMTreatmentCohort: 13,
  BispecificsCartTreatmentCohort: 14,
  LineOfTherapy: 15,
  NonCohortDefiningTreatment: 16,
  AdditionalTreatmentCharacteristics: 17,
  NDMMSCTCharacteristics: 18,
  TTCharacteristics: 19,
  CartCharacteristics: 20,
  IMWGReporting: 22,
  TreatmentResponseProgression: 23,
  AdverseEvents: 24,
  AEReporting: 25,
  ValidateSubmit: 26,
  ChartSummary:27,
};

export const SideMenuPageList = [
  { id: 1, title: "PATIENT SCREENING", path: "/PatientScreening" },
  { id: 2, title: "CHART SELECTION", path: "/ChartSelection", hasParent: true },

  { title: "ELIGIBILITY CRITERIA", notClickable: true },
  {
    id: 4,
    title: "GENERAL INFORMATION",
    path: "/GeneralInformation",
    hasParent: true,
  },
  { id: 5, title: "1L NDMM CRITERIA", path: "/NDMMCriteria", hasParent: true },
  { id: 6, title: "RRMM CRITERIA", path: "/RRMMCriteria", hasParent: true },
  {
    id: 7,
    title: "ADDITIONAL EXCLUSION CRITERIA",
    path: "/ExclusionCriteria",
    hasParent: true,
    ndmm_rrmm_dependent: true
  },
  {
    id: 8,
    title: "TRANSITION TO CHART ABSTRACTION",
    path: "/TransitionToChartAbstraction",
    hasParent: true,
  },

  { id: 9, title: "PATIENT DEMOGRAPHICS", path: "/Demographics" },
  { id: 10, title: "DISEASE HISTORY", path: "/DiseaseHistory" },
  { id: 11, title: "COMORBIDITIES", path: "/Comorbidities", ndmm_rrmm_dependent: true },

  {
    id: 12,
    title: "COHORT-DEFINING TREATMENT CHARACTERISTICS",
    path: "/CohortDefiningTreatment",
  },
  { id: 13, title: "1L NDMM TREATMENT COHORT", path: "/NDMMTreatmentCohort", hasParent: true, ndmm_dependent: true },
  {
    id: 14,
    title: "BISPECIFICS/CAR-T TREATMENT COHORT",
    path: "/BispecificsCartTreatmentCohort",
    hasParent: true,
    rrmm_dependent: true
  },
  { id: 15, title: "LINE OF THERAPY ALGORITHM", path: "/LineOfTherapy", hasParent: true, },


  {
    id: 16,
    title: "NON-COHORT-DEFINING TREATMENT CHARACTERISTICS",
    path: "/NonCohortDefiningTreatment",
    ndmm_rrmm_dependent: true
  },

  { id: 17, title: "ADDITIONAL TREATMENT CHARACTERISTICS", notClickable: true },
  {
    id: 18,
    title: "1L NDMM SCT CHARACTERISTICS",
    path: "/NDMMSCTCharacteristics",
    hasParent: true,
    ndmm_dependent: true
  },
  {
    id: 19,
    title: "TECLISTAMAB AND TALQUETAMAB CHARACTERISTICS",
    path: "/TTCharacteristics",
    hasParent: true,
    rrmm_dependent: true
  },
  { id: 20, title: "CAR-T CHARACTERISTICS", path: "/CarTCharacteristics", hasParent: true, rrmm_dependent: true },

  { title: "CLINICAL OUTCOMES", notClickable: true },
  { id: 22, title: "STANDARD IMWG REPORTING CRITERIA", path: "/IMWGReporting", hasParent: true, },
  {
    id: 23,
    title: "TREATMENT RESPONSE AND PROGRESSION",
    path: "/TreatmentResponseProgression",
    hasParent: true,
    ndmm_rrmm_dependent: true
  },

  { id: 24, title: "ADVERSE EVENTS (AEs)", path: "/AdverseEvents", rrmm_dependent: true },

  { id: 25, title: "AE REPORTING", path: "/AEReporting" },

  { id: 26, title: "VALIDATE SUBMIT", path: "/ValidateSubmit" },
];
export const AdminOnlyRoutes = [
  "/manageabstractors",
  "/managecenters",
  "/managerounds"
];

export const ToastMessages = {
  Save_Success: "Data has been Successfully saved.",
  Fail: "Oops!, Something went wrong.",
};

export const PageAction = {
  add: 1,
  edit: 2,
};

export const DefaultAGGridProps = {
  ROWS_PER_PAGE: 10,
  ROW_HEIGHT: 50,
  TABLE_HEIGHT: 50 * 10 + 100,
  NOT_FOUND: "No Records Found",
};
export const ErrorMessages = {
  SAVE_ERROR: "Error occurred while saving.",
  LOAD_ERROR: "Error occurred while loading.",
  EMIAL_NONEXISTENT: "Email address does not exists.",
};

export const Role = {
  Admin: 1,
  Center: 2,
};
export const PageDesignConstants = {
  backgroundColor: "#dff3f6",
  fontColor: "steelblue",
  fontWeight: "bold",
  PageDescriptionStyle: {
    fontWeight: "500",
    paddingBottom: "10px",
    textDecoration: "underline",
  },
};

export const NDMMCohortTreatment = {
  DRd_TIE: "DR/DRd (TIE)",
  DVRd_TE: "DVR/DVRd (TE)",
  DVRd_TIE: "DVR/DVRd (TIE)",
  DKRd_TE: "DKR/DKRd (TE)",
  DKRd_TIE: "DKR/DKRd (TIE)",
  VRd_TE: "VR/VRd (TE)",
  VRd_TIE: "VR/VRd (TIE)",
  ISAVRD_TE: "IsaVR/IsaVRd (TE)",
  ISAVRD_TIE: "IsaVR/IsaVRd (TIE)",
};

export const NDMMCohortTreatmentID = {
  DRd_TIE: 1,
  DVRd_TE: 2,
  DVRd_TIE: 3,
  DKRd_TE: 4,
  DKRd_TIE: 4,
  VRd_TE: 5,
  VRd_TIE: 6,
  ISAVRD_TE: 7,
  ISAVRD_TIE: 8,
};

export const RRMMCohortTreatment = {
  TECVAYLI: 9,
  TALVEY: 10,
  ELREXFIO: 11,
  Linvoseltamab: 12,
  CARVYKTI: 13,
  ABECMA: 14,
}

export const NDMMCohortAgent = {
  Daratumumab: "Daratumumab",
  Bortezomib: "Bortezomib",
  Lenalidomide: "Lenalidomide",
  Carfilzomib: "Carfilzomib",
  Isatuximab: "Isatuximab",
  Dexamethasone: "Dexamethasone",
  Prednisone: "Prednisone",
  Prednisolone: "Prednisolone",
};

export const DiscontinuingReasons = {
  ReasonPtDeath: "Patient death",
  ReasonAdv: "Adverse event",
  ReasonDProg: "Disease progression",
  ReasonInsuff: "Insufficient response without progression",
  ReasonCost: "Cost or insufficient insurance coverage",
  ReasonEnd: "End of treatment plan",
  ReasonCr: "Achieved CR or better (per the 2016 IMWG criteria)",
  ReasonMrd5: "Achieved (sustained) MRD negativity (10-5)",
  ReasonMrd6: "Achieved (sustained) MRD negativity (10-6)",
  ReasonVgpr: "Achieved VGPR or better (per the 2016 IMWG criteria)",
  ReasonSub: "Suboptimal response (PR or SD) (per the 2016 IMWG criteria)",
  ReasonPtDesc: "Patient's decision (if none of the above)",
  ReasonPhy: "Physician's decision (if none of the above)",
  ReasonOther: "Other, please specify: ",
};
//value must be same as lotnumber table's PK in DB
export const LOT = {
  LOT1: 1,
  LOT2: 2,
  LOT3: 3,
  LOT4: 4,
};
export const LockType = {
  PatientLock: 1,
  EligibilityCriteriaLock: 2
}
export const PatientStatus = {
  None: 1,
  Eligible: 2,
  Terminated: 3,
  Pending: 4
}  
export const UrlType = {
    DataDictionary : 1,
    UserManual : 2
}