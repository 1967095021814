
import { AgGridReact } from 'ag-grid-react';
import PatientDetails from '../../Components/PatientInformation'
import { CenterMenuItems, DefaultAGGridProps, ErrorMessages, HTTPResponse, PageAction, ToastMessages, ToastMessageType } from '../../Helpers/Enums';
import ConfirmPopUp from '../../Components/ConfirmPopUp';
import { useContext, useEffect, useRef, useState } from 'react';
import { RoundFormModal } from './RoundFormModal';
import CenterMenu from '../../Components/CenterMenu';
import { AppContext } from '../../Contexts/AppContextProvider';
import { CenterService } from '../../WebApiServices/Center.service';
import { Center_Msgs, CommonError_Msgs, Round_Msgs } from '../../Helpers/HelperText';
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from '../../Helpers/Utilities';
import ErrorField from '../../Components/ErrorField';
import { Dropdown } from 'react-bootstrap';

export const ManageRounds = () => {
    const [loading, setLoading] = useState(true);
    const { ShowToast, ToggleLoader, HandleSessionTimeout, ShowActionPopUp } = useContext(AppContext);
    const [allRounds, setAllRounds] = useState([]);
    const [filteredRounds, setFilteredRounds] = useState([]);
    const [showRoundForm, setShowRoundForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const searchRef = useRef("");
    const [roundId, setRoundId] = useState(0);
    const [centerRoundId, setCenterRoundId] = useState(0);
    const [isRoundComplete, setIsRoundComplete] = useState(false);
    const centerId = GetLocalStorageData("editCenterId") ?? 0;
    const [centerDetails, setCenterDetails] = useState({
        name: "",
        activeRoundId: null,
        activeRoundIdBeforeChange: null,
        isTest: false
    });
    const InitialHardErrList = {
        name_empty: false,
        name_exists: false,
    }
    const [hardErrList, setHardErrList] = useState(InitialHardErrList);

    const [activeRoundDropdown, setActiveRoundDropdown] = useState([]);//Active round dropdown (contains current and next round if exists)
    const [activeRound, setActiveRound] = useState('Select Round');
    // const [activeRoundId, setActiveRoundId] = useState();
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteRoundId, setDeleteRoundId] = useState(0);

    const iconStyle = {
        width: 25,
        margin: 10,
        cursor: "pointer",
    };
    const completedIconStyle = {
        width: 25,
        margin: 10,
    };
    const activeIconStyle = {
        width: '100%',
        height: '60%',
        margin: 10,
    };
    const columnDefs = [
        {
            headerName: "Round Name",
            field: "name",
            width: 200,
            cellRenderer: (params) => <div> {params.data.roundName} </div>,
            resizable: false,
        },
        {
            headerName: "Start Date",
            width: 200,
            cellRenderer: (params) => <div
            > {params.data.startDate}</div>,
            cellClass: "td",
        },
        {
            headerName: "End Date",
            width: 200,
            cellRenderer: (params) => <div
            > {params.data.endDate}</div>,
            cellClass: "td",
        },
        // Assign Treatment new chart count to Round
        {
            width: 90,
            cellRenderer: (params) => (
                <img
                    src="../Assets/images/Icon-awesome-edit.png"
                    alt="edit"
                    style={iconStyle}
                    onClick={() => { AssignClick(params.data.centerRoundId, params.data.roundId, !isStringEmpty(params.data.endDate)) }}
                    title="Edit"
                />
            ),
            cellClass: "td",
            resizable: false,
        },
        {
            width: 110,
            cellRenderer: (params) => (
                !isStringEmpty(params.data.endDate) ?
                    <img
                        src="../Assets/images/round-completion.png"
                        alt="Completed"
                        style={completedIconStyle}
                        title='Completed'
                    />
                    :params.data.isActive ?
                        <img
                            src="../Assets/images/Active-icon.png"
                            alt="Active"
                            style={activeIconStyle}
                        />
                        :<></>
            ),
            cellClass: "td",
            resizable: false,
        },
        // DELETE
        {
            width: 80,
            cellRenderer: (params) => {

                return !params.data.isActive && centerDetails.activeRoundId != null &&
                    params.data.roundId > centerDetails.activeRoundId &&
                    params.node.rowIndex === params.api.getDisplayedRowCount() - 1// Determine if this is the last row
                    ? (
                        <img
                            src="../Assets/images/Icon-material-delete.png"
                            alt="delete"
                            style={{ ...iconStyle, width: "17px" }}
                            onClick={() => DeleteClick(params.data.roundId)}
                            title="Delete Round"
                        />
                    ) : null;
            },
            cellClass: "td",
            resizable: false,
        },

    ];
    const defaultColDef = {
        resizable: true,
        sortable: true,
        // flex: 1,
        cellClass: "td no-border",
        //headerClass: 'ag-cell-header'
    };
    // CSS class for highlighting edited and new rows
    const getRowClass = (params) => {
        return params.data.isEdited ? 'edited-row' : '';
    };
    const gridOptions = {
        columnDefs,
        defaultColDef,
        rowHeight: DefaultAGGridProps.ROW_HEIGHT,
        pagination: true,
        paginationPageSize: DefaultAGGridProps.ROWS_PER_PAGE,
        getRowClass
    };
    const agGridLocaleText = {
        noRowsToShow: DefaultAGGridProps.NOT_FOUND,
    };
    const GetTableHeight = () => {
        return 100 + (filteredRounds?.length < 4
            ? 50 * 4
            : filteredRounds?.length < 11
                ? 50 * filteredRounds?.length
                : 50 * 10);
    };

    useEffect(() => {

        const FetchData = async () => {
            ToggleLoader(true)
            await GetAllRounds();
            ToggleLoader(false)
        }
        FetchData();
    }, []);

    useEffect(() => {

        setFilteredRounds(allRounds?.filter((c) =>
            c.roundName.replace(/\s/g, '').toLowerCase().includes(searchQuery.replace(/\s/g, '').toLowerCase())
        ).sort(c => c.roundId));
    }, [searchQuery, allRounds])


    const GetAllRounds = async () => {
        try {

            const response = await CenterService.GetAllRounds(centerId);
            if (response?.status === HTTPResponse.Unauthorized) {
                HandleSessionTimeout();
            }
            else if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                if (data != null) {
                    const { item1, item2 } = data;//item1-> round list, item2-> center's name, active round
                    const roundList = item1;
                    const { centerName, activeRoundId, isTest } = item2;

                    setCenterDetails(prev => ({
                        ...prev,
                        name: centerName,
                        activeRoundId,
                        activeRoundIdBeforeChange: activeRoundId,
                        isTest
                    }));

                    let list = [];
                    if (roundList && roundList.length > 0) {

                        list = roundList.map(r => ({
                            centerRoundId: r.centerRoundId,
                            roundId: r.roundId,
                            roundName: r.roundName,
                            startDate: r.startDate,
                            endDate: r.endDate,
                            isActive: r.roundId === activeRoundId,
                        }));
                    }
                    setAllRounds(list);

                    const array = PopulateActiveRoundDropdown(roundList, activeRoundId);
                    setActiveRoundDropdown(array);
                }
            }
            else {
                throw response.error;
            }
            setLoading(false);
        } catch {
            setLoading(false);
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };

    const HandleSearch = () => {
        setSearchQuery(searchRef.current.value);
    }

    const AddNew = async (a) => {
        try {
            const userId = GetLoggedInUserID() ?? 0;
            ToggleLoader(true);
            const response = await CenterService.AddNewRound(userId, centerId);
            ToggleLoader(false);

            if (response?.status === HTTPResponse.OK) {
                const newRound = response.data;

                setAllRounds(prev => ([
                    ...prev,
                    {
                        centerRoundId: newRound.centerRoundId,
                        roundId: newRound.roundId,
                        roundName: newRound.roundName
                    }
                ]))
                //Add new round in dropdown if previously it contained only active round.
                if (activeRoundDropdown && activeRoundDropdown.length < 2) {

                    setActiveRoundDropdown(prev => ([
                        ...prev,
                        {
                            ...newRound
                        }
                    ]))
                }

                ShowToast(Round_Msgs.ADD_SUCCESS, ToastMessageType.Success);
            }
            else {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    ShowToast(Round_Msgs.ADD_FAIL, ToastMessageType.Failed);
            }
        } catch {
            ShowToast(Round_Msgs.ADD_FAIL, ToastMessageType.Failed);
        }
    }
    const AssignClick = (crId, roundId, isRoundComplete) => {
        setShowRoundForm(true);
        setRoundId(roundId);
        setCenterRoundId(crId);
        setIsRoundComplete(isRoundComplete);
    };
    const CloseModal = (roundId) => {
        setShowRoundForm(false);

        if (roundId !== 0) {

            const updatedList = allRounds.map((row) => {

                if (row.roundId === roundId) {
                    return { ...row, isEdited: true };
                }
                return { ...row, isEdited: false };
            })

            setAllRounds(updatedList);
        }
    }

    const DeleteClick = async (id) => {
        setShowDeleteConfirm(true);
        setDeleteRoundId(id);
    }
    //DELETE CONFIRM
    const DeleteYesClick = () => {
        HandleDelete();
        setShowDeleteConfirm(false)
    }
    const DeleteNoClick = () => {
        setShowDeleteConfirm(false)
    }
    const HandleDelete = async () => {
        try {
            ToggleLoader(true)
            const response = await CenterService.DeleteRound(centerId, deleteRoundId);
            ToggleLoader(false)

            if (response?.status === HTTPResponse.OK) {

                setAllRounds(prev => prev?.filter(a => a.roundId !== deleteRoundId));
                setFilteredRounds(prev => prev?.filter(a => a.roundId !== deleteRoundId));

                //Remove deleted round if preent in Active Round Dropdown
                setActiveRoundDropdown(prev => prev?.filter(a => a.roundId !== deleteRoundId));

                ShowToast(Round_Msgs.DELETE_SUCCESS, ToastMessageType.Success);
            }
            else {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    ShowToast(Round_Msgs.DELETE_FAIL, ToastMessageType.Failed);
            }
        } catch {
            ShowToast(Round_Msgs.DELETE_FAIL, ToastMessageType.Failed);
        }
    }

    const HandleRoundChange = async (roundId, roundName) => {

        if (roundId === centerDetails.activeRoundIdBeforeChange) {
            SetActiveRoundDropdownState(roundId, roundName);
        } else {
            //Check if any unsubmitted patients are present in current round
            const incompletePtsArr = await MoveToNextRoundAllowed();
            
            if(incompletePtsArr == null){//bad request
                return;
            }
            if (incompletePtsArr && incompletePtsArr.length > 0) {
                const ShowAlertBox = async () => {
                    const choice = await ShowActionPopUp(
                        "Alert",
                        Round_Msgs.NEXT_ROUND_NOT_ALLOWED.replace("{count}", incompletePtsArr.length) + 
                        " Patient IDs: " + incompletePtsArr.map(String).join(', '),
                        "Ok",
                    );
                    return;
                };
                await ShowAlertBox();
            } else {
                setShowProgressModal(true);
            }
        }
    }
    //MOVE TO NEXT ROUND CONFIRM
    const YesClick = () => {
        setShowProgressModal(false);

        //Since the dropdown has only 2 items, the name and id will be of the 2nd one
        SetActiveRoundDropdownState(activeRoundDropdown[1].roundId, activeRoundDropdown[1].roundName);
    }
    const NoClick = () => {
        setShowProgressModal(false);
    }

    // Check if any unsubmitted patient are present in current round
    const MoveToNextRoundAllowed = async () => {
        try {
            ToggleLoader(true)
            const response = await CenterService.AllPatientsSubmittedInCurrentRound(centerId);
            ToggleLoader(false)

            if (response?.status === HTTPResponse.OK) {
                return response?.data;
            }
            else {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else {
                    return null;
                }
            }
        } catch {
            return null;
        }
    }
    const SetActiveRoundDropdownState = (id, name) => {
        setActiveRound(name);
        setCenterDetails(prev => ({
            ...prev,
            activeRoundId: id,
        }));
    };
    //Save center name and active round and sets center status as False
    const SaveCenterNameActiveRound = async () => {
        try {
            setHardErrList(InitialHardErrList);

            if (Validate()) {
                const payload = {
                    CenterId: centerId,
                    CenterName: centerDetails.name,
                    ActiveRoundId: centerDetails.activeRoundId,
                    IsTest: centerDetails.isTest,
                    UserId: GetLoggedInUserID(),
                }

                ToggleLoader(true);
                const response = await CenterService.SaveCenterNameAndActiveRound(payload);
                ToggleLoader(false);

                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else if (response?.status === HTTPResponse.Conflict) {
                        setHardErrList((prev) => ({
                            ...prev,
                            name_exists: true,
                        }))
                    }
                    else {
                        throw response.error;
                    }
                } else {
                    // let userDetails = GetLocalStorageData("user")
                    // userDetails = userDetails && JSON.parse(userDetails);
                    // const userArr = { ...userDetails, isCenterLocked: false  };
                    // AddLocalStorageData("user", JSON.stringify(userArr));
                    
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);

                    //set the new active round in the grid
                    // let updatedList = [];
                    // updatedList = allRounds.map(row => {

                    //     if (row.roundId === centerDetails.activeRoundId) {
                    //         return { ...row, isActive: true, startDate: response.data };
                    //     }
                    //     return { ...row, isActive: false };
                    // })
                    // setAllRounds(updatedList);

                    // //Remove previous round and add next round if available
                    // const array = PopulateActiveRoundDropdown();
                    // setActiveRoundDropdown(array);
                    await GetAllRounds();
                }
            }
        }
        catch (error) {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    };
    const PopulateActiveRoundDropdown = (roundList = allRounds, activeRoundId = centerDetails.activeRoundId) => {
        let activeRound = roundList.find(a => a.roundId === activeRoundId);
        const activeRoundIndex = roundList.findIndex(a => a.roundId === activeRoundId);

        let activeRoundList = [];

        if (activeRound) {
            setActiveRound(activeRound.roundName);
            activeRoundList.push(activeRound);
            //Next round
            let rowBelow = null;
            if (activeRoundIndex !== -1) {
                // Ensure that there is a row below the current index
                if (activeRoundIndex < roundList.length - 1) {
                    rowBelow = roundList[activeRoundIndex + 1];
                    if (rowBelow) {
                        activeRoundList.push(rowBelow);
                    }
                }
            }
        }
        return activeRoundList;

    }
    const Validate = () => {

        let result = true;

        if (isStringEmpty(centerDetails.name)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                name_empty: true,
            }))
        }
        return result;
    }

    const HandleNameChange = (e) => {
        const { name, value } = e.target;

        setCenterDetails((prev) => ({
            ...prev,
            name: value,
        }));
        setHardErrList((prev) => ({
            ...prev,
            name_empty: false,
            name_exists: false,
        }));

    }
    return (
        <>
            <section className="survey-patient-details-section">
                <PatientDetails />
            </section>
            <CenterMenu activeTab={CenterMenuItems.Round} />
            <section className="main-common-page-section main-common-page-section-min-ht">
                <div className="container custom-inline-padding" id="round-grid-page">
                    <div className="row mb-3">
                        <div className="col-md-6 col-lg-6">
                            <span><b>Center ID: {centerId}</b></span> 
                            <span style={{marginLeft: "10px"}}><b>Center Name: </b></span>
                            <input
                                id="centerName"
                                name="name"
                                className="input-dash"
                                type="text"
                                placeholder="Center"
                                value={centerDetails.name}
                                onChange={HandleNameChange}
                                maxLength={100}
                            // disabled={!isAdmin}// not required as Round grid is only seen by Admin
                            />
                            <ErrorField show={hardErrList.name_empty} message={Center_Msgs.NAME_EMPTY} style={{ paddingLeft: '0px' }} />
                            <ErrorField show={hardErrList.name_exists} message={Center_Msgs.NAME_EXISTS} style={{ paddingLeft: '0px' }} />
                         </div>
                            <div style={{width:"30%"}}>
                                <span><b>Active Round: </b></span>
                                <Dropdown className='custom-dropdown-caret' >
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="w150 custom-dropdown-button"
                                    //disabled={!isAdmin}
                                    >
                                        {activeRound}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="custom-dropdown-ht custom-dropdown-menu" >
                                        {activeRoundDropdown.map((r) => (
                                            <Dropdown.Item
                                                as="button"
                                                name="activeRoundDropdown"
                                                key={r.roundId}
                                                value={r.roundName}
                                                className={`custom-dropdown-item ${r.roundName === activeRound ? 'custom-dropdown-item-selected' : undefined}`}
                                                onClick={() => HandleRoundChange(r.roundId, r.roundName)}
                                            >
                                                {r.roundName}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div style={{width:"10%"}}>
                                <span><b>Test </b></span>
                                    <input
                                        type="checkbox"
                                        id="isTest"
                                        name="isTest"
                                        checked={centerDetails.isTest}
                                        onChange={() => {
                                            setCenterDetails((prev) => ({
                                                ...prev,
                                                isTest: !prev.isTest
                                            }));}
                                        }
                                    />
                            </div>
                            <div style={{width:"10%"}}>
                                <button
                                    className="custom-save-button btn btn-outline-light btn-lg add-new-patient"
                                    type="submit"
                                    onClick={SaveCenterNameActiveRound}
                                >
                                    Save
                                </button>
                        </div>
                    </div>

                    <div className="row mb-3">{/*action-sec-fbox */}
                        <div className="col-md-4 col-lg-4 ">{/* search-custom-sec */}
                            <div className="searchBar">
                                <input
                                    className="searchQueryInput-class"
                                    id="searchQueryInput"
                                    type="text"
                                    name="searchQueryInput"
                                    placeholder="Round Name"
                                    ref={searchRef}
                                    onChange={HandleSearch}
                                />
                                <button
                                    id="searchQuerySubmit"
                                    type="submit"
                                    name="searchQuerySubmit"
                                >
                                    <img src="../Assets/images/Icon-feather-search.png" width="20px" />
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <button
                                // data-bs-toggle="modal"
                                // data-bs-target="#AddCenterModal"
                                className="btn btn-outline-light btn-lg add-new-patient"
                                type="submit"
                                onClick={AddNew}
                            >
                                + Add New Round
                            </button>
                        </div>
                        {/* <div className="col-md-3 col-lg-3">
                            <span>Active Round: <b>{activeRound}</b></span>
                        </div> */}
                    </div>
                    <div className="user-tab">
                        {!loading &&
                            (
                                <div
                                    className="ag-theme-alpine "
                                    style={{
                                        height: filteredRounds?.length === 0 ? 322 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                                        width: "100%",
                                    }}
                                >
                                    <AgGridReact
                                        rowData={filteredRounds}
                                        gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                                        onPaginationChanged={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                    />

                                </div>
                            )
                        }
                    </div>
                </div>
            </section>

            {showRoundForm && (
                <RoundFormModal
                    CloseModal={CloseModal}
                    roundId={roundId}
                    centerRoundId={centerRoundId}
                    isRoundComplete={isRoundComplete}
                />
            )}
            {showProgressModal && <ConfirmPopUp YesClick={YesClick} NoClick={NoClick} title="Do you want to progress to the next round?"
                description={`${activeRoundDropdown[0].roundName.replace("Round ", "")} -> ${activeRoundDropdown[1].roundName.replace("Round ", "")}`} />}
            {showDeleteConfirm && <ConfirmPopUp YesClick={DeleteYesClick} NoClick={DeleteNoClick} />}
        </>
    )
}